import React, { useEffect, useState } from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { useLanguage } from '../LanguageContext';
import { useNavigate } from 'react-router-dom';
import { FaXTwitter } from "react-icons/fa6";

const englishData = {
    title : 'Tejash Patel',
    text : 'Currently He is a Chairman of KDCC Bank, Nadiad, APMC, Petlad,    Petlad-Sojitra Taluka Sahkari Kharid Vechan Shang, Petlad   & Director of Gujcomarsol, Ahmedabad. Guj. State Co.Op.  Tobacco Growers Federation Ltd.,Anand. & RGB Delegate Krubhko, Delhi.   ',
    explore : 'Explore',
    home: 'Home',
    about :'About Me', 
    achivement : 'Achivement',
    projects : 'Projects',
    initiatives : 'Initiatives',  
    press :'Press & Media', 
    galery :'Gallery', 
    contactUs :'Contact us',  
    contact :'Contact', 
    add :'Address: Tejash B. Patel (Jigabhai), Shree Nivas, Pipdav, Taluka: Sojitra, District: Anand 388460.', 
    phone :'Phone:(+91) 98250 45742', 
    email :'Email: sahkar@tejashpatel.in', 
    inputTitle :'Connect with us', 
    inputPlasholder :'Enter your email', 
    reserve :'© 2024 Tejash Patel | Designed by',  
  }
  
  const gujratData = {
    title : 'તેજશ પટેલ',
    text : "હાલમાં તેઓ KDCC બેંક, નડિયાદ, APMC, પેટલાદના ચેરમેન છે.  પેટલાદ-સોજીત્રા તાલુકા સહકારી ખારીડ વેચન શાંગ, પેટલાદ અને ગુજકોમરસોલ, અમદાવાદના ડાયરેક્ટર. ગુજ. સ્ટેટ કો.ઓપ. ટોબેકો ગ્રોવર્સ ફેડરેશન લિ., આણંદ. અને આરજીબી ડેલિગેટ ક્રુભકો, દિલ્હી.",
    explore : 'અન્વેષણ કરો',
    home: 'હોમ',
    about :'મારો પરિચય', 
    achivement : 'સિદ્ધિઓ',
    projects : 'પ્રોજેક્ટ્સ',
    initiatives : 'મારી પહેલ',  
    press :'પ્રેસ', 
    galery :'ગેલેરી', 
    contactUs :'સંપર્ક કરો',
    contact :'અમારો સંપર્ક કરો', 
    add :'સરનામું: તેજશ બી. પટેલ (જીગાભાઈ), શ્રી નિવાસ, પીપડાવ, તાલુકો: સોજીત્રા, જિલ્લો: આણંદ 388460.', 
    phone :'ફોન:(+91) 98250 45742', 
    email :'ઈમેલ: sahkar@tejashpatel.in', 
    inputTitle :'અમારી સાથે જોડાઓ', 
    inputPlasholder :'તમારું ઈમેલ એડ્રેસ લખો', 
    reserve :'© 2024 તેજશ પટેલ | દ્વારા ડિઝાઇન કરવામાં આવી છે',  
  }
const Footer = () => { 
    const { language } = useLanguage();
    const [footerData, setFooterData] = useState()
  
    useEffect(()=>{
      if(language){
          setFooterData(englishData)   
      }else{
          setFooterData(gujratData)
      } 
  },[language])

  const navigate = useNavigate()

  return (
    <div style={{background:'#F5F2F2'}}>
        <br />
        <div class='col-11 m-auto row '>
            <div class='col-md-6 text-start'>
                <p class='fs-1 mt-4'>{footerData?.title}</p>
                <div class='col-sm-10 mt-3'>
                    <p class='justifyT'>{footerData?.text}</p>
                </div>
            </div>
            <div class='col-md-6 row'>
                <div class='col-sm-4 text-start'>
                    <p class='fw-bold mt-4'>{footerData?.explore}</p>
                    <div class='mt-3'>
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/')}>{footerData?.home}</p>
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/about-us')}>{footerData?.about}</p>
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/achievements')}>{footerData?.achivement}</p> 
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/projects')}>{footerData?.projects}</p>
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/initiatives')}>{footerData?.initiatives}</p>
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/press')}>{footerData?.press}</p>
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/gallery')}>{footerData?.galery}</p> 
                        <p class='mt-1' style={{cursor:'pointer'}} onClick={()=>navigate('/contact')}>{footerData?.contactUs}</p>
                     
                    </div>
                </div>

                <div class='col-sm-8 text-start'>
                    <p class='fw-bold mt-4'>{footerData?.contact}</p>
                    <div class='mt-3'>
                        <p>{footerData?.add}</p>
                        <p class='mt-4'>{footerData?.phone}</p>
                        <p class='mt-4'>{footerData?.email}</p> 
                    </div>
                    <div class='d-flex gap-2 mt-4 flex-wrap footerIcon'>
                        <a href='https://www.facebook.com/officialtejash/' target='_blank'  ><FaFacebookF/></a>
                        <a href=''  ><FaXTwitter /></a>
                        <a href='https://www.instagram.com/tejashofficial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D' target='_blank'><FaInstagram /></a>
                        <a href='' ><FaLinkedinIn /></a>
                        <a href='' ><FaYoutube /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class='col-11 m-auto mt-4'>
            {/* <div class='col-lg-4 col-md-5 col-11 text-start'> 
                <p>{footerData?.inputTitle}</p>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder={footerData?.inputPlasholder} aria-label="Username" aria-describedby="basic-addon1"/>
                    <span class="input-group-text" id="basic-addon1" style={{background:'#E6EAFF',cursor:'pointer'}}><i class="bi bi-arrow-right"></i></span>
                </div>
            </div> */}
            <div class='text-start mt-4'>
                <p> {footerData?.reserve}  <span style={{color: 'rgb(128, 192, 77)', fontWeight: 'bold'}}> </span><a href="https://hi-labsolution.com/" target="_blank"><img src="https://hi-labsolution.com/hlogo.png" alt="Hi-Lab Solution"/></a></p>
            </div>
             <br />
        </div>
    </div>
  )
}

export default Footer