import React, { useEffect, useState } from 'react'
import './Achievements2.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from '../LanguageContext';
import { Helmet } from 'react-helmet';

const englishData = {
    section1: {
        title: 'Achievements'
    },
    section2: {
        year1: {
            year: "2023 - Present",
            title: 'Chairman',
            text: ' The Kaira Dist. Central Co-op Bank LTD (KDCC Bank)',
            address: 'Nadiad, Gujarat.',
            img: `${require('../../images/i7.jpg')}`
        },
        year2: {
            year: "2018 - Present",
            title: "Chairman",
            text: "The Petlad-Sojatra Taluka Solar-Energy Manufacturer Co-operative Society Ltd.",
            address: "Petlad, Anand",
            img: `${require('../../images/i6.jpg')}`
        },
        year3: {
            year: "2017 - Present",
            title: "Director ",
            text: "The Gujarat State Co Operative Tobacco Growers Federation Ltd.",
            address: "Anand",
            img: `${require('../../images/i9.jpg')}`
        },
        year4: {
            year: "2015 - Present",
            title: "Chairman ",
            text: "The Petlad Taluka Farm Development Industry Service Co-Operative Society Ltd.",
            address: "Petlad, Anand",
            img: `${require('../../images/tp17.jpg')}`
        },
        year5: {
            year: "2014 - Present",
            title: "Chairman ",
            text: "The Piplav Milk Producer’s Co-Op. Societies Ltd.",
            address: "Piplav, Anand",
            img: `${require('../../images/tp14.png')}`
        },
        year6: {
            year: "2012 - Present",
            title: "Chairman ",
            text: "The Agricultural Product Market Committee (APMC,Petlad)",
            address: "Petlad",
            img: `${require('../../images/tp20.jpg')}`
        },
        year7: {
            year: "2011 - Present",
            title: "Director ",
            text: "The Piplav Tobacco Producer Co-operative Societies Ltd.",
            address: "Piplav, Anand",
            img: `${require('../../images/i9.jpg')}`
        },
        year8: {
            year: "2006 - Present",
            title: "Director ",
            text: "The Kheda Dist. Central Co-op. Bank Ltd. (KDCC Bank)",
            address: "Nadiad, Gujarat.",
            img: `${require('../../images/i7.jpg')}`
        },
        year9: {
            year: "2005 - Present",
            title: "Regional General board (RGB) Delegate",
            text: "Krishak Bharati Cooperative Limited (KRIBHCO)",
            address: "Delhi",
            img: `${require('../../images/i4.jpg')}`
        },
        year10: {
            year: "2004 - Present",
            title: "Director ",
            text: "The Gujarat State Co Operative Marketing Federation Ltd. (Gujcomarsol)",
            address: "Ahmedabad",
            img: `${require('../../images/i5.jpg')}`
        },
        // year11: {
        //     year: "2003-Present",
        //     title: "Director ",
        //     text: "The Gujarat State Co Operative Marketing Federation Ltd. (Gujcomarsol)",
        //     address: "Ahmedabad",
        //     img: `${require('../../images/i5.jpg')}`
        // },
        year11: {
            year: "2003-Present",
            title: "Director ",
            text: "The Piplav Service Co-operative Society Ltd.",
            address: "Piplav, Anand",
            img: `${require('../../images/tp18.jpg')}`
        },
        year12: {
            year: "1998 - Present",
            title: "Chairman ",
            text: "The Piplav Village Development Board",
            address: "Piplav,Anand",
            img: `${require('../../images/tp19.jpg')}`
        },
    }
}

const gujratData = {
    section1: {
        title: 'સિદ્ધિઓ'
    },
    section2: {
        year1: {
            year: "2023 - વર્તમાન",
            title: 'ચેરમેન   ',
            text: 'ધી ખેડા ડિસ્ટ્રિક્ટ સેન્ટ્રલ કો.ઓ.બેંક.લી. (કેડીસીસી બેંક)',
            address: 'નડિયાદ, ગુજરાત.',
            img: `${require('../../images/i7.jpg')}`
        },
        year2: {
            year: "2018 - વર્તમાન",
            title: "અધ્યક્ષ",
            text: "પેટલાદ-સોજાત્રા તાલુકા સોલાર-એનર્જી ઉત્પાદક સહકારી મંડળી લિ.",
            address: "પેટલાદ, આણંદ",
            img: `${require('../../images/i6.jpg')}`
        },
        year3: {
            year: "2017 - વર્તમાન",
            title: "દિગ્દર્શક ",
            text: "ગુજરાત સ્ટેટ કો ઓપરેટિવ ટોબેકો ગ્રોવર્સ ફેડરેશન લિ.",
            address: "આણંદ",
            img: `${require('../../images/i9.jpg')}`
        },
        year4: {
            year: "2015 - વર્તમાન",
            title: "અધ્યક્ષ ",
            text: "પેટલાદ તાલુકા ફાર્મ ડેવલપમેન્ટ ઈન્ડસ્ટ્રી સર્વિસ કો-ઓપરેટિવ સોસાયટી લિ.",
            address: "પેટલાદ, આણંદ",
            img: `${require('../../images/tp17.jpg')}`
        },
        year5: {
            year: "2014 - વર્તમાન",
            title: "અધ્યક્ષ ",
            text: "પીપલાવ દૂધ ઉત્પાદકની કો-ઓપ. સોસાયટીઝ લિ.",
            address: "પીપલાવ, આણંદ",
            img: `${require('../../images/tlYear14.jpg')}`
        },
        year6: {
            year: "2012 - વર્તમાન",
            title: "અધ્યક્ષ ",
            text: "કૃષિ ઉત્પાદન બજાર સમિતિ (APMC, પેટલાદ)",
            address: " મૂકો",
            img: `${require('../../images/tlYear12.jpg')}`
        },
        year7: {
            year: "2011 - વર્તમાન",
            title: "દિગ્દર્શક ",
            text: "પીપલાવ ટોબેકો પ્રોડ્યુસર કો-ઓપરેટિવ સોસાયટી લિ.",
            address: "પીપલાવ,આણંદ",
            img: `${require('../../images/i9.jpg')}`
        },
        year8: {
            year: "2006 - વર્તમાન",
            title: "દિગ્દર્શક ",
            text: "ખેડા જિ. સેન્ટ્રલ કો-ઓપ. બેંક લિ. (KDCC બેંક)",
            address: " નડિયાદ, ગુજરાત.",
            img: `${require('../../images/i7.jpg')}`
        },
        year9: {
            year: "2005 - વર્તમાન",
            title: "પ્રાદેશિક જનરલ બોર્ડ (RGB) પ્રતિનિધિ",
            text: "કૃષક ભારતી કોઓપરેટિવ લિમિટેડ (KRIBHCO)",
            address: "દિલ્હી",
            img: `${require('../../images/i4.jpg')}`
        },
        year10: {
            year: "2004 - વર્તમાન",
            title: "દિગ્દર્શક ",
            text: "ગુજરાત સ્ટેટ કો ઓપરેટિવ માર્કેટિંગ ફેડરેશન લિ. (ગુજકોમરસોલ)",
            address: "અમદાવાદ",
            img: `${require('../../images/i5.jpg')}`
        },
        // year11: {
        //     year: "2003 - વર્તમાન",
        //     title: "દિગ્દર્શક ",
        //     text: "ગુજરાત સ્ટેટ કો ઓપરેટિવ માર્કેટિંગ ફેડરેશન લિ. (ગુજકોમરસોલ)",
        //     address: "અમદાવાદ",
        //     img: `${require('../../images/i5.jpg')}`
        // },
        year11: {
            year: "2003 - વર્તમાન",
            title: "દિગ્દર્શક ",
            text: "પીપલાવ સેવા સહકારી મંડળી લિ.",
            address: "પીપલાવ,આણંદ",
            img: `${require('../../images/tp18.jpg')}`
        },
        year12: {
            year: "1998 - વર્તમાન",
            title: "અધ્યક્ષ ",
            text: "પીપલાવ ગ્રામ વિકાસ બોર્ડ",
            address: " પીપલાવ, આણંદ",
            img: `${require('../../images/tp19.jpg')}`
        },
    }
}

const Achievements2 = () => {

    const { language } = useLanguage();
    const [achivmentsData, setAchivmentsData] = useState()

    useEffect(() => {
        if (language) {
            setAchivmentsData(englishData)
        } else {
            setAchivmentsData(gujratData)
        }
    }, [language])

    useEffect(() => {
        let path = document.querySelector('path');
        let pathLength = path.getTotalLength();
        let lineContainer = document.querySelector('.line-container');

        path.style.strokeDasharray = pathLength + " " + pathLength;
        path.style.strokeDashoffset = pathLength;

        window.addEventListener('scroll', () => {
            let scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) * 1.1 / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
            let drawLength = pathLength * scrollPercentage;
            path.style.strokeDashoffset = Math.max(pathLength - drawLength)
            path.style.transition = "0s"

        });
    }, []);
    return (
        <div style={{ overflow: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div style={{ height: '50vh' }}></div>
            <div class='blueBg ' style={{ position: 'absolute', height: '100vh', top: '0', left: '0', width: '100%', zIndex: '0', alignItems: 'start' }}>
                <div>
                    <div style={{ height: '20vh' }}></div>
                    <p class='experienceText fw-bold'>{achivmentsData?.section1?.title} </p>
                </div>
            </div>

            <div class='position-relative achivPc ' >
                <div style={{ height: '150px' }}></div>
                <div class=' row justify-content-center ' style={{ width: '100%', height: '100%' }}>
                    <div class=''>

                        <svg width="100%" height="620vh" viewBox="0 0 338 3539" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 1L194.5 98L231.956 122.013C347.5 196.091 345.956 365.451 229.08 437.409L211.379 448.307C96.5961 518.976 101.991 687.599 221.057 750.786V750.786C338.191 812.947 345.806 977.932 234.898 1050.62L179.559 1086.89C65.1882 1161.85 76.1364 1332.86 199.131 1392.63V1392.63C327.511 1455 332.273 1636.17 207.347 1705.21L192.949 1713.17C75.3927 1778.13 76.6537 1947.55 195.164 2010.75L210.903 2019.15C318.576 2076.57 324.067 2228.88 220.808 2293.91L189.442 2313.67C92.3137 2374.84 105.865 2520.43 212.614 2562.63V2562.63C321.77 2605.77 332.724 2755.95 230.984 2814.48L199.42 2832.63C86.5794 2897.55 96.521 3063.5 216.305 3114.47L234.588 3122.26C366.371 3178.34 371.666 3363.18 243.31 3426.71L19.5 3537.5" stroke="#1d2b77" stroke-width="2" />
                        </svg>


                    </div>

                </div>

                <div class='position-absolute m-auto ' style={{ top: '0', left: '0', width: '100%' }}>
                    <div class='col-11 m-auto'>
                        <div class='row justify-content-start align-items-center mt-5  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year1?.year}</p>
                                        <p class='fw-bold fs-5 ' >{achivmentsData?.section2?.year1?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year1?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year1?.address}</p>
                                    </div>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year1?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year2?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year2?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year2?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year2?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year2?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year3?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year3?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year3?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year3?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year3?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year4?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year4?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year4?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year4?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year4?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year5?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year5?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year5?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year5?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year5?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year6?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year6?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year6?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year6?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year6?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year7?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year7?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year7?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year7?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year7?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year8?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year8?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year8?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year8?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year8?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year9?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year9?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year9?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year9?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year9?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year10?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year10?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year10?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year10?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year10?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year11?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year11?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year11?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year11?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year11?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year12?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year12?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year12?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year12?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year12?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year13?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year13?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year13?.title}</p>
                                        <p class='fw-bold ' >{achivmentsData?.section2?.year13?.text}</p>
                                        <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year13?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class='position-relative achivMb ' >
                <div class='col-11 m-auto'>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year1?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year1?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year1?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year1?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year1?.address}</p>
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year2?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year2?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year2?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year2?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year2?.address}</p>
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year3?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year3?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year3?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year3?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year3?.address}</p>
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year4?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year4?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year4?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year4?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year4?.address}</p>
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year5?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year5?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year5?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year5?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year5?.address}</p>
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year6?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year6?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year6?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year6?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year6?.address}</p>
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year7?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year7?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year7?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year7?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year7?.address}</p>
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year8?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year8?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year8?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year8?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year8?.address}</p>
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year9?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year9?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year9?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year9?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year9?.address}</p>
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year10?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year10?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year10?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year10?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year10?.address}</p>
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year11?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year11?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year11?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year11?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year11?.address}</p>
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year12?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year12?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year12?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year12?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year12?.address}</p>
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year13?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year13?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year13?.title}</p>
                            <p class='fw-bold' >{achivmentsData?.section2?.year13?.text}</p>
                            <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year13?.address}</p>
                        </div>
                    </div>


                </div>
            </div>
            <div style={{ height: '150px' }}></div>

        </div>
    )
}

export default Achievements2