import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const englishData = {
    section1: {
        title1: 'KDCC Bank ',
        title2: " Tejas Patel's Leadership",
        text: 'A remarkable journey of transformation led by a visionary leader.'
    },
    section2: {
        text: "KDCC Bank's story is one of inspiring growth and transformation, fueled by the  exceptional leadership of Tejas Pate. Under his guidance, the bank has embarked  on a remarkable journey, exceeding expectations and redefining cooperative achievements, and how Tejas Pate's vision has propelled KDCC Bank to new heights.",
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'Goals',
            points: ["Expand the bank&#39;s reach and customer base.", "Enhance the quality and efficiency of banking services.", "Promote financial inclusion and empower rural communities.", "Foster a culture of innovation and continuous improvement."]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Achievements - Empowering Growth, Enriching Lives.',
            text: [
                "The bank witnessed a phenomenal surge in its customer base, expanding by a staggering",
                "Gone were the days of long queues. New digital banking with whatsapp banking initiatives empowered customers to bank on-the-go, 24/7.",
                "The branch network grew significantly, extending a helping hand and financial services to previously underserved rural areas.",
                "Innovative loan products were introduced, catering to diverse needs, from financing  agricultural ventures to supporting small businesses.",
                "Total deposits and advances witnessed a remarkable growth, reflecting the bank's increasing strength and customer trust.",
                " Tagline: Empowering Growth, Enriching Lives. (Retained for its strong message)"
            ]
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "Bank's Growth with Tejas Patel (Leading with Vision, Delivering Progress.)",
            text: [
                "Tejas Pate's leadership ushered in an era of streamlined operations, with improved efficiency that translated into faster loan approvals and quicker customer service. ",
                "Robust risk management practices were implemented, ensuring the bank&#39;s financial stability and fostering a sense of security for both customers and investors. ",
                "A strong team culture, built on motivation and recognition, became a hallmark of the  bank. Employees were empowered to excel, fostering a sense of ownership and dedication. ",
                "Customer focus became the bank&#39;s guiding principle. Tejas Pate prioritized exceptional customer service, ensuring every interaction was positive and personalized. ",
                "The bank actively championed social responsibility initiatives, giving back to the  community through educational programs and financial literacy workshops.",
                "Tagline: Leading with Vision, Delivering Progress. (Retained for its strong message)",
            ]
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'Transformation',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'Visionary Leadership',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'Innovation',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'Customer Centricity',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'Efficiency',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'Community Focus',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'Impact and Benefits',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '19.37+ crore ',
                text: 'Share Capital'
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: '31.00+ crore ',
                text: 'Net Profit'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '2502.33+ Lakh',
                text: 'Deposits'
            },
            // {
            //     img: `${require('../images/benefit4.png')}`,
            //     title: '200+ Distributors',
            //     text: 'Distributors across India'
            // },
            // {
            //     img: `${require('../images/benefit5.png')}`,
            //     title: '400+ Dealers',
            //     text: 'Lorem Ipsum sit'
            // },
            // {
            //     img: `${require('../images/benefit6.png')}`,
            //     title: '1 million +',
            //     text: 'Happy Farmers'
            // },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'Conclusion',
        text: "KDCC Bank's success story under Tejas Pate's leadership is a shining example of  what can be achieved with a clear vision, a commitment to innovation, and an  unwavering dedication to serving the community. The bank's transformation is far  from over, and with Tejas Pate at the helm, KDCC Bank is poised to continue  soaring to new heights, shaping a brighter financial future for all its stakeholders.",
        button1: 'Donate',
        button2: 'Volunteer'
    }
}

const gujratData = {
    section1: {
        title1: 'KDCC બેંક ',
        title2: "તેજસ પટેલનું નેતૃત્વ",
        text: 'એક સ્વપ્નદ્રષ્ટા નેતાના નેતૃત્વમાં પરિવર્તનની અદભૂત યાત્રા.'
    },
    section2: {
        text: "કેડીસીસી બેંકની વાર્તા પ્રેરણાદાયી વૃદ્ધિ અને પરિવર્તનની છે, જે તેજસ પટેલ ના અસાધારણ નેતૃત્વને કારણે છે.  તેમના માર્ગદર્શન હેઠળ, બેંકે અપેક્ષાઓ કરતાં વધુ અને સહકારી બેંકિંગ શ્રેષ્ઠતાને પુનઃવ્યાખ્યાયિત કરીને નોંધપાત્ર  પ્રવાસ શરૂ કર્યો છે. આ પ્રોજેક્ટ બેંકના વ્યૂહાત્મક ધ્યેયો, તેની પ્રભાવશાળી સિદ્ધિઓ અને તેજસ પાટેની દ્રષ્ટિએ કેવી રીતે KDCC બેંકને નવી ઉંચાઈઓ પર લઈ જવામાં આવી છે તેની તપાસ કરે છે.",
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'લક્ષ્યો',
            points: ["બેંકની પહોંચ અને ગ્રાહક આધારને વિસ્તૃત કર્યા.", "બેંકિંગ સેવાઓની ગુણવત્તા અને કાર્યક્ષમતા વધારી.", "નાણાકીય સમાવેશને પ્રોત્સાહન આપ્યા અને ગ્રામીણ સમુદાયોને સશક્ત બનાવ્યા", "નવીનતા અને સતત સુધારણાને કરાવી ને પ્રગતિ કરી."]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "સિદ્ધિઓ - સશક્તિકરણ વૃદ્ધિ, ગ્રામીણ જીવનને સમૃદ્ધ બનાવવું.",
            text: [
                " બેંકે તેના ગ્રાહક આધારમાં અસાધારણ ઉછાળો જોયો છે, જે આશ્ચર્યજનક રીતે વિસ્તરી રહ્યો છે.",
                "લાંબી કતારોના દિવસો ગયા. વ્હોટ્સએપ બેંકિંગ પહેલ સાથે નવી ડિજિટલ બેંકિંગે ગ્રાહકોને 24/7 સફરમાં બેન્કિંગ કરવા માટે સશક્તિકરણ કર્યું. ",
                 "બ્રાન્ચ નેટવર્ક નોંધપાત્ર રીતે વિકસી રહ્યું છે, અગાઉથી ઓછી સેવા ધરાવતા ગ્રામીણ વિસ્તારોમાં મદદ અને નાણાકીય સેવાઓનો વિસ્તાર કર્યો. ",
                 "કૃષિ સાહસોને ધિરાણ આપવાથી માંડીને નાના વ્યવસાયોને ટેકો આપવા સુધીની વિવિધ જરૂરિયાતોને પૂરી કરવા  માટે નવીન લોન પ્રોડક્ટ્સ રજૂ કરી. ",
                 " કુલ થાપણો અને એડવાન્સિસમાં નોંધપાત્ર વૃદ્ધિ જોવા મળી છે, જે બેંકની વધતી જતી શક્તિ અને ગ્રાહક  વિશ્વાસને પ્રતિબિંબિત કરે છે.",
                 " ",

            ]
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "તેજસ પટેલ સાથે બેંકની વૃદ્ધિ (દ્રષ્ટિ સાથે અગ્રણી, પ્રગતિ પહોંચાડી રહી છે.)",
            text: [
                "તેજસ પટેલ ના નેતૃત્વએ સુવ્યવસ્થિત કામગીરીના યુગની શરૂઆત કરી, જેમાં સુધારેલ કાર્યક્ષમતા ઝડપી લોન  મંજૂરીઓ અને ઝડપી ગ્રાહક સેવામાં અનુવાદિત થઈ.",
                "બેંકની નાણાકીય સ્થિરતા સુનિશ્ચિત કરીને અને ગ્રાહકો અને રોકાણકારો બંને માટે સુરક્ષાની ભાવનાને ઉત્તેજન   આપતા, મજબૂત જોખમ સંચાલન પદ્ધતિઓ લાગુ કરવામાં આવી હતી.",
                "એક મજબૂત ટીમ સંસ્કૃતિ, જે પ્રેરણા અને માન્યતા પર બનેલી છે, તે બેંકની ઓળખ બની ગઈ છે. કર્મચારીઓને  માલિકી અને સમર્પણની ભાવનાને ઉત્તેજન આપતા, શ્રેષ્ઠ બનવાની સત્તા આપવામાં આવી.",
                "ગ્રાહકનું ધ્યાન બેંકનો માર્ગદર્શક સિદ્ધાંત બન્યો. તેજસ પટેલ અસાધારણ ગ્રાહક સેવાને પ્રાથમિકતા આપી, દરેક  ક્રિયાપ્રતિક્રિયા હકારાત્મક અને વ્યક્તિગત તેની ખાતરી કરી.",
                "બેંકે શૈક્ષણિક કાર્યક્રમો અને નાણાકીય સાક્ષરતા વર્કશોપ દ્વારા સમુદાયને પાછા આપવા માટે સામાજિક જવાબદારીની પહેલને સક્રિયપણે ચહલ કરી.",
            ]
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'પરિવર્તન',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'વિઝનરી લીડરશીપ',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'નવીનતા',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'ગ્રાહક કેન્દ્રિતતા',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'કાર્યક્ષમતા',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'સમુદાય ફોકસ',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'અસર અને લાભો',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '1903.89+ લાખ',
                text: 'શેર મૂડી'
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: '3000.00+ લાખ',
                text: 'ચોખ્ખો નફો'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '214680.86+ લાખ',
                text: 'થાપણો'
            },
            // {
            //     img: `${require('../images/benefit4.png')}`,
            //     title: '200+ Distributors',
            //     text: 'Distributors across India'
            // },
            // {
            //     img: `${require('../images/benefit5.png')}`,
            //     title: '400+ Dealers',
            //     text: 'Lorem Ipsum sit'
            // },
            // {
            //     img: `${require('../images/benefit6.png')}`,
            //     title: '1 million +',
            //     text: 'Happy Farmers'
            // },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'નિષ્કર્ષ',
        text: "તેજસ પટેલ ના નેતૃત્વ હેઠળ KDCC બેંકની સફળતાની ગાથા સ્પષ્ટ દ્રષ્ટિ, નવીનતા પ્રત્યેની પ્રતિબદ્ધતા અને  સમુદાયની સેવા કરવા માટેના અતૂટ સમર્પણ સાથે શું હાંસલ કરી શકાય તેનું શ્રેષ્ટ ઉદાહરણ છે. બેંકનું રૂપાંતરણ હજી  દૂર છે, અને તેજસ પટેલ ના સુકાન સાથે, KDCC બેંક તેના તમામ હિતધારકો માટે ઉજ્જવળ નાણાકીય ભવિષ્યને  આકાર આપીને નવી ઊંચાઈઓ પર આગળ વધવા માટે તૈયાર છે.",
        button1: 'દાન કરો',
        button2: 'સ્વયંસેવક'
    }
}




const KdccBankProject = () => {
    const { language } = useLanguage();

    const [projectDetailData, setProjectDetailData] = useState()

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        if (language) {
            setProjectDetailData(englishData)
        } else {
            setProjectDetailData(gujratData)
        }
    }, [language])


    return (
        <div style={{ overflowX: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='projectDetailTopKdcc' >
                <div class='col-lg-4 col-md-6 col-10 text-start ' style={{ position: 'absolute', bottom: '5%', overflow: 'hidden' }}>
                    <div class='col-12' style={{ background: '#EA5136' }} data-aos="fade-right">
                        <p class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title1}</p>
                    </div>
                    <p data-aos="fade-right" class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title2}</p>

                    <div class='col-10 text-start mt-3 text-white' style={{ marginLeft: '10%' }} data-aos="fade-right">
                        <p class='fs-5'>{projectDetailData?.section1?.text}</p>
                    </div>
                </div>
            </div>

            <div class='col-11 row text-start m-auto my-5'>
                <div class='col-md-7 mt-3'>
                    <div class='col-lg-8' data-aos="fade-right">
                        <p class='fs-3'>{projectDetailData?.section2?.text}</p>
                    </div>
                </div>
                <div class='col-md-5'>
                    {/* <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-calendar2-fill mx-1"></i></span> <span>{projectDetailData?.section2?.timeLine?.title}</span></p>
                        <p class='mx-4'>{projectDetailData?.section2?.timeLine?.weeks}</p>
                    </div> */}
                    <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-rocket-takeoff-fill mx-1"></i></span> <span>{projectDetailData?.section2?.goals?.title}</span></p>
                        <ul>
                            {
                                projectDetailData?.section2?.goals?.points?.map((el) => {
                                    return (

                                        <li class=''>{el}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>


            </div>

            <div class='row'>
                {
                    projectDetailData?.section3?.map((el) => {
                        return (
                            <div class='col-md-6 text-start' data-aos="fade-right" style={{ background: `${el.background}` }}>
                                <div class='col-11 m-auto text-white'>
                                    <div class='my-4'>
                                        <img src={el.icon} style={{ width: '36px', height: '36px' }} alt="" />
                                    </div>
                                    <p class='fs-4 fw-semibold my-4'>{el.title}</p>
                                    <ul>

                                        {el?.text?.map((el) => {
                                            return (
                                                <li>{el}</li>
                                            )
                                        })}
                                    </ul>
                                    <br />
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            {/* <div class='col-lg-10 col-11 m-auto my-5' data-aos="zoom-in">
                <p class='tJustify'>{projectDetailData?.section4?.text}</p>
            </div> */}

            {/* <div class='row justify-content-end m-0'>
                <div class='col-md-11 rounded-5 row justify-content-end p-0' style={{ backgroundImage: `url(${projectDetailData?.section4?.imgbg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                    <div class='col-md-6 col-sm-8 col-11 text-end d-flex justify-content-end text-black' data-aos="fade-left" style={{ background: '#FBF7A9', clipPath: 'inset(0 0 0 0 round 150px 0 0 150px)' }}>
                        <div class='my-5 col-11 p-2'>
                            <p class='fs-3 fw-bold text-black'>{projectDetailData?.section4?.title}</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class='benifitsUp'>

                <div class='benifitsUpPoint1' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point1Img} style={{ width: '120px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point1}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint2' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point2Img} style={{ width: '35px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point2}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint3' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point3Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point3}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint4' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point4Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point4}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint5' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point5Img} style={{ width: '20px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point5}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint6' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point6Img} style={{ width: '100px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point6}</p>
                    </div>
                </div>
            </div>
            <br />

            <div style={{ background: '#727CB6', color: 'white' }}>
                <br />
                <p class='h1 my-3' data-aos="fade-down">{projectDetailData?.section6?.title}</p>
                {/* <p class='col-lg-4 col-md-6 col-11 m-auto' data-aos="fade-down">{projectDetailData?.section6?.text}</p> */}
                <div class='col-11 row justify-content-center m-auto my-4'>
                    {
                        projectDetailData?.section6?.cart?.map((el) => {
                            return (
                                <div class='col-lg-4 col-md-6 my-3' data-aos="fade-up">
                                    <div class='col-11 m-auto rounded-4  h-100 p-2' style={{ backgroundImage: 'linear-gradient( rgba(65,72,116,0.9), rgba(65,72,116,0.1))' }}>
                                        <img class='mt-3' src={el.img} style={{ width: '60px' }} alt="" />
                                        <p class='fs-5 fw-bold my-3'>{el.title}</p>
                                        <p class='my-3'>{el.text}</p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <br />

            </div>

            <div class=' row flex-column-reverse flex-md-row '>
                <div class='col-md-6'>
                    <div class='col-sm-10 col-11 m-auto text-start my-4'>
                        <p class='fs-1 fw-bold' data-aos="fade-right" style={{ color: '#EA5136' }}>{projectDetailData?.section7?.title}</p>
                        <p class='my-2 fs-5' data-aos="fade-right">{projectDetailData?.section7?.text}</p>
                        {/* <div class='mt-3' data-aos="fade-right">
                            <button class="custom-btn btnBlue px-2 "><span>{projectDetailData?.section7?.button1}</span></button>
                            <button class="custom-btn btnOrange"><span>{projectDetailData?.section7?.button2}</span></button>
                        </div> */}
                    </div>
                </div>
                <div class='col-md-6' data-aos="zoom-in">
                    <img src={projectDetailData?.section7?.img} style={{ width: '100%', minHeight: '300px' }} alt="" />
                </div>
            </div>


        </div>
    )
}

export default KdccBankProject