 
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer';
import UperFooter from './components/Footer/UperFooter';
import Home from './components/Home/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Projects from './components/Projects'; 
import ProjectDetails from './components/ProjectDetails';
import ScrollToTop from './ScrollToTop';
import Achievements2 from './components/Achievements2/Achievements2';
import Initiatives from './components/Initiatives';
import Press from './components/Press';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import KdccBankProject from './components/KdccBankProject';
import Sauraurja from './components/Sauraurja';
import { useLanguage } from './components/LanguageContext';
import SakarMall from './components/SakarMall';
 
 
 

function App() {

  const {language} = useLanguage()

  return (
    <div className={language ? "App poppins" : "App anekGujarati"}>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about-us' element={<About/>}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/projects-details' element={<ProjectDetails/>}/>
        <Route path='/achievements' element={<Achievements2/>}/>
        <Route path='/initiatives' element={<Initiatives/>}/> 
        <Route path='/press' element={<Press/>}/> 
        <Route path='/gallery' element={<Gallery/>}/> 
        <Route path='/contact' element={<Contact/>}/>  

        <Route path='/kdcc-bank' element={<KdccBankProject/>}/>  
        <Route path='/sauraurja' element={<Sauraurja/>}/>  
        <Route path='/sakar-mall' element={<SakarMall/>}/>  



       
      </Routes> 
      <UperFooter/>
      <Footer/>
    </div>
  );
}

export default App;
