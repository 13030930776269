import React, { useState, useEffect } from 'react';
import './footer.css';
import { useLanguage } from '../LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './footer.css';

// const Positions = [
//   {
//     img : `${require('../../images/p1.png')}`
//   },
//   {
//     img : `${require('../../images/p2.png')}`
//   },
//   {
//     img : `${require('../../images/p3.png')}`
//   },
//   {
//     img : `${require('../../images/p4.png')}`
//   },
//   {
//     img : `${require('../../images/p5.png')}`
//   },
//   {
//     img : `${require('../../images/p6.png')}`
//   }, 
// ]

const englishData = {
  title : 'Current Position',
  positions : [
    {
      img : `${require('../../images/t1.png')}`
    },
    {
      img : `${require('../../images/t2.png')}`
    },
    {
      img : `${require('../../images/t3.png')}`
    },
    {
      img : `${require('../../images/t4.png')}`
    },
    {
      img : `${require('../../images/t5.png')}`
    },
    {
      img : `${require('../../images/t6.png')}`
    }, 
    {
      img : `${require('../../images/t7.png')}`
    },  
       {
      img : `${require('../../images/t8.png')}`
    }, 
    {
      img : `${require('../../images/t9.png')}`
    }, 
    {
      img : `${require('../../images/t10.png')}`
    }, 
  ]
}

const gujratData = {
  title : 'વર્તમાન હોદ્દા',
  positions : [
    {
      img : `${require('../../images/t1.png')}`
    },
    {
      img : `${require('../../images/t2.png')}`
    },
    {
      img : `${require('../../images/t3.png')}`
    },
    {
      img : `${require('../../images/t4.png')}`
    },
    {
      img : `${require('../../images/t5.png')}`
    },
    {
      img : `${require('../../images/t6.png')}`
    }, 
    {
      img : `${require('../../images/t7.png')}`
    },  
       {
      img : `${require('../../images/t8.png')}`
    }, 
    {
      img : `${require('../../images/t9.png')}`
    }, 
    {
      img : `${require('../../images/t10.png')}`
    }, 
  ]
}

const UperFooter = () => {
  const [visiblePositions, setVisiblePositions] = useState(); // Number of initially visible positions

  const { language } = useLanguage();
  const [uperFooterData, setUperFooterData] = useState()

  useEffect(()=>{
    AOS.init({duration: 1000});
}) 

  useEffect(()=>{
    if(language){
        setUperFooterData(englishData)   
    }else{
        setUperFooterData(gujratData)
    } 
},[language])

  const totalPositions = uperFooterData?.positions?.length;

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const documentHeight = document.documentElement.offsetHeight;

    if (scrollPosition === documentHeight) {
      // User has scrolled to the bottom
      if (visiblePositions < totalPositions) {
        // Load more positions
        setVisiblePositions(visiblePositions ); // Increase the number of visible positions
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visiblePositions]);

  return (
    <div>
      <div className='col-11 m-auto'>
        <br />
        <p className='fs-1' data-aos="fade-down">{uperFooterData?.title}</p>
        <br />
        <div className="uperFoterPosition">
          <div className="uperFoterPosition__item">
            {uperFooterData?.positions.slice(0, visiblePositions).map((el, index) => (
              <React.Fragment key={index}>
                <img src={el.img} alt="" class='uperFooterImg'   />
                <span className="uperFoterPosition__seperator"></span>
              </React.Fragment>
            ))}
          </div>
        </div>  
        <br />
      </div>
    </div>
  );
};

export default UperFooter;
