import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const englishData = {
    section1: {
        title1: 'Sahkar Mall',
        title2: "Empowering Rural Commerce",
        text: 'Sahkar Mall transforms rural commerce, delivering affordable products and creating sustainable economic opportunities.'
    },
    section2: {
        text: "Sahkar Mall pioneers e-commerce in rural India, providing essential goods at fair prices through a cooperative model. By bridging the gap between urban and rural markets, Sahkar Mall helps rural communities access the products they need, without the extra costs of travel and time.",
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'Goals',
            points: ["Bring essential products directly to rural homes, saving time, money, and effort.", "Easy-to-navigate mobile and web platforms make online shopping simple, even for first-time users.",
                "Create local job opportunities, empowering rural communities and driving regional economic development.", "Use advanced technology like voice search and data analytics to ensure a seamless user experience."
            ]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Benefits of Choosing Sahkar Mall',
            text: [
                "<p><b>Cost Savings: </b> No need to travel to cities for essential goods—shop from home and save on transportation.</p>",
                "<p><b>Job Creation:</b> Local employment through cooperative societies helps improve livelihoods within rural communities.</p>",
                "<p><b>Economic Growth: </b> Supporting cooperative commerce boosts local economies and contributes to sustainable rural development.</p>",
                "<p><b>Innovation for All:</b> Advanced features like voice search and analytics make e-commerce accessible and efficient, even for first-time users.</p>",
            ]
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "Achievements",
            text: [
                "<p><b>Widespread Reach:</b>  Successfully connected thousands of rural households across multiple states to affordable e-commerce.</p>",
                "<p><b>Job Opportunities:</b> Created over 5,000 employment opportunities in rural areas through cooperative engagement.</p>",
                "<p><b>Customer Satisfaction:</b> Consistently high ratings for customer service, delivery efficiency, and product quality.</p>",
                "<p><b>Innovative Solutions</b> Introduced voice search to enhance accessibility, setting a new standard in rural e-commerce.</p>",
      
            ]
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'Rural e-commerce',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'Employment',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'Innovation',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'Affordability',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'Convenience',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'Cooperative Growth',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'Impact and Benefits',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '1000+',
                text: 'Rural Households '
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: '5000+',
                text: 'Customer Satisfaction'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '5000+',
                text: 'Employment Opportunities'
            },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'Conclusion',
        text: "Sahkar Mall is revolutionizing how rural India shops—by offering affordable, essential products, and boosting local economies through an innovative, cooperative e-commerce approach.",
        button1: 'Donate',
        button2: 'Volunteer'
    }
}

const gujratData = {
    section1: {
        title1: 'સહકાર મોલ',
        title2: "ગ્રામીણ વાણિજ્ય સશક્તિકરણ",
        text: 'સહકાર મોલ ગ્રામીણ વાણિજ્યને પરિવર્તિત કરે છે, પોસાય તેવા ઉત્પાદનોની ડિલિવરી કરે છે અને ટકાઉ આર્થિક તકોનું સર્જન કરે છે.'
    },
    section2: {
        text: "સહકારી મોડલ દ્વારા વાજબી ભાવે આવશ્યક ચીજવસ્તુઓ પૂરી પાડતા સહકાર મોલ ગ્રામીણ ભારતમાં ઈ-કોમર્સનો પાયોનિયર છે. શહેરી અને ગ્રામીણ બજારો વચ્ચેના અંતરને દૂર કરીને, સહકાર મોલ ગ્રામીણ સમુદાયોને મુસાફરી અને સમયના વધારાના ખર્ચ વિના, તેઓને જોઈતી પ્રોડક્ટ્સ એક્સેસ કરવામાં મદદ કરે છે.",
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'લક્ષ્યો',
            points: ["જરૂરી ઉત્પાદનો સીધા ગ્રામીણ ઘરોમાં લાવો, સમય, પૈસા અને પ્રયત્નોની બચત કરો.","નેવિગેટ કરવા માટે સરળ મોબાઇલ અને વેબ પ્લેટફોર્મ ઓનલાઈન શોપિંગને સરળ બનાવે છે, પ્રથમ વખતના વપરાશકર્તાઓ માટે પણ.",
                "સ્થાનિક રોજગારીની તકોનું સર્જન કરો, ગ્રામીણ સમુદાયોને સશક્તિકરણ કરો અને પ્રાદેશિક આર્થિક વિકાસને આગળ ધપાવો.","સીમલેસ વપરાશકર્તા અનુભવ સુનિશ્ચિત કરવા માટે વૉઇસ શોધ અને ડેટા એનાલિટિક્સ જેવી અદ્યતન તકનીકનો ઉપયોગ કરો.",
            ]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "સહકાર મોલ પસંદ કરવાના ફાયદા",
            text: [
                 "<p><b> ખર્ચ બચત:</b> આવશ્યક ચીજવસ્તુઓ માટે શહેરોમાં મુસાફરી કરવાની જરૂર નથી - ઘરેથી ખરીદી કરો અને પરિવહન પર બચત કરો.</p>",
                 "<p><b>નોકરીનું સર્જન:</b>  સહકારી મંડળીઓ દ્વારા સ્થાનિક રોજગાર ગ્રામીણ સમુદાયોમાં આજીવિકા સુધારવામાં મદદ કરે છે.</p>",
                 "<p><b>આર્થિક વૃદ્ધિ:</b>  સહકારી વાણિજ્યને ટેકો આપવાથી સ્થાનિક અર્થતંત્રોને વેગ મળે છે અને ટકાઉ ગ્રામીણ વિકાસમાં યોગદાન મળે છે.</p>",
                 "<p><b> બધા માટે નવીનતા:</b> વૉઇસ શોધ અને એનાલિટિક્સ જેવી અદ્યતન સુવિધાઓ ઇ-કોમર્સ સુલભ અને કાર્યક્ષમ બનાવે છે, પ્રથમ વખતના વપરાશકર્તાઓ માટે પણ.</p>", 
            ]
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "સિદ્ધિઓ",
            text: [
                "<p><b>વ્યાપક પહોંચ:</b>  અનેક રાજ્યોમાં હજારો ગ્રામીણ પરિવારોને સસ્તું ઈ-કોમર્સ સાથે સફળતાપૂર્વક જોડ્યા.</p>",
                "<p><b>નોકરીની તકો:</b>  સહકારી જોડાણ દ્વારા ગ્રામીણ વિસ્તારોમાં રોજગારીની 5,000 થી વધુ તકોનું સર્જન કર્યું.</p>",
                "<p><b> ગ્રાહક સંતોષ:</b> ગ્રાહક સેવા, વિતરણ કાર્યક્ષમતા અને ઉત્પાદન ગુણવત્તા માટે સતત ઉચ્ચ રેટિંગ.</p>",
                "<p><b> ઇનોવેટિવ સોલ્યુશન્સ:</b> સુલભતા વધારવા માટે વૉઇસ સર્ચની રજૂઆત કરી, ગ્રામીણ ઇ-કોમર્સમાં એક નવું ધોરણ સેટ કર્યું.</p>", 
            ]
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'ગ્રામીણ ઈ-કોમર્સ',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'રોજગાર',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'નવીનતા',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'પોષણક્ષમતા',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'સુવિધા',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'સહકારી વૃદ્ધિ',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'અસર અને લાભો',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '1000+',
                text: 'ગ્રામીણ પરિવારો'
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: '5000+',
                text: 'ગ્રાહક સંતોષ'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '5000+',
                text: 'રોજગારની તકો'
            },
            // {
            //     img: `${require('../images/benefit4.png')}`,
            //     title: '200+ Distributors',
            //     text: 'Distributors across India'
            // },
            // {
            //     img: `${require('../images/benefit5.png')}`,
            //     title: '400+ Dealers',
            //     text: 'Lorem Ipsum sit'
            // },
            // {
            //     img: `${require('../images/benefit6.png')}`,
            //     title: '1 million +',
            //     text: 'Happy Farmers'
            // },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'નિષ્કર્ષ',
        text: "સહકાર મોલ ક્રાંતિકારી છે કે કેવી રીતે ગ્રામીણ ભારતમાં ખરીદી કરે છે - સસ્તું, આવશ્યક ઉત્પાદનો ઓફર કરીને અને નવીન, સહકારી ઈ-કોમર્સ અભિગમ દ્વારા સ્થાનિક અર્થતંત્રોને પ્રોત્સાહન આપીને",
        button1: 'દાન કરો',
        button2: 'સ્વયંસેવક'
    }
}




const SakarMall = () => {
    const { language } = useLanguage();

    const [projectDetailData, setProjectDetailData] = useState()

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        if (language) {
            setProjectDetailData(englishData)
        } else {
            setProjectDetailData(gujratData)
        }
    }, [language])


    return (
        <div style={{ overflowX: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='projectDetailTopSouraurja' style={{ backgroundImage: `url(${require('../images/sakarMall.jpg')})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div class='col-lg-5 col-md-6 col-10 text-start ' style={{ position: 'absolute', bottom: '5%', overflow: 'hidden' }}>
                    <div class='col-12' style={{ background: '#EA5136' }} data-aos="fade-right">
                        <p class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title1}</p>
                    </div>
                    <p data-aos="fade-right"  class='fs-1' style={{color:'#EA5136', width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title2}</p>

                    <div class='col-10 text-start mt-3' style={{color:'#EA5136', marginLeft: '10%' }} data-aos="fade-right">
                        <p class='fs-5'>{projectDetailData?.section1?.text}</p>
                    </div>
                </div>
            </div>

            <div class='col-11 row text-start m-auto my-5'>
                <div class='col-md-7 mt-3'>
                    <div class='col-lg-8' data-aos="fade-right">
                        <p class='fs-3'>{projectDetailData?.section2?.text}</p>
                    </div>
                </div>
                <div class='col-md-5'>
                    {/* <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-calendar2-fill mx-1"></i></span> <span>{projectDetailData?.section2?.timeLine?.title}</span></p>
                        <p class='mx-4'>{projectDetailData?.section2?.timeLine?.weeks}</p>
                    </div> */}
                    <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-rocket-takeoff-fill mx-1"></i></span> <span>{projectDetailData?.section2?.goals?.title}</span></p>
                        <ul>
                            {
                                projectDetailData?.section2?.goals?.points?.map((el) => {
                                    return (
                                        <li  >{el}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>


            </div>

            <div class='row'>
                {
                    projectDetailData?.section3?.map((el) => {
                        return (
                            <div class='col-md-6 text-start' data-aos="fade-right" style={{ background: `${el.background}` }}>
                                <div class='col-11 m-auto text-white'>
                                    <div class='my-4'>
                                        <img src={el.icon} style={{ width: '36px', height: '36px' }} alt="" />
                                    </div>
                                    <p class='fs-4 fw-semibold my-4'>{el.title}</p>
                                    <ul>

                                        {el?.text?.map((el) => {
                                            return (
                                                <li dangerouslySetInnerHTML={{ __html: el }}></li>
                                            )
                                        })}
                                    </ul>
                                    <br />
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            {/* <div class='col-lg-10 col-11 m-auto my-5' data-aos="zoom-in">
                <p class='tJustify'>{projectDetailData?.section4?.text}</p>
            </div> */}

            {/* <div class='row justify-content-end m-0'>
                <div class='col-md-11 rounded-5 row justify-content-end p-0' style={{ backgroundImage: `url(${projectDetailData?.section4?.imgbg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                    <div class='col-md-6 col-sm-8 col-11 text-end d-flex justify-content-end text-black' data-aos="fade-left" style={{ background: '#FBF7A9', clipPath: 'inset(0 0 0 0 round 150px 0 0 150px)' }}>
                        <div class='my-5 col-11 p-2'>
                            <p class='fs-3 fw-bold text-black'>{projectDetailData?.section4?.title}</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class='benifitsUp'>

                <div class='benifitsUpPoint1' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point1Img} style={{ width: '120px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point1}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint2' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point2Img} style={{ width: '35px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point2}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint3' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point3Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point3}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint4' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point4Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point4}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint5' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point5Img} style={{ width: '20px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point5}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint6' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point6Img} style={{ width: '100px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point6}</p>
                    </div>
                </div>
            </div>
            <br />

            <div style={{ background: '#727CB6', color: 'white' }}>
                <br />
                <p class='h1 my-3' data-aos="fade-down">{projectDetailData?.section6?.title}</p>
                {/* <p class='col-lg-4 col-md-6 col-11 m-auto' data-aos="fade-down">{projectDetailData?.section6?.text}</p> */}
                <div class='col-11 row justify-content-center m-auto my-4'>
                    {
                        projectDetailData?.section6?.cart?.map((el) => {
                            return (
                                <div class='col-lg-4 col-md-6 my-3' data-aos="fade-up">
                                    <div class='col-11 m-auto rounded-4  h-100 p-2' style={{ backgroundImage: 'linear-gradient( rgba(65,72,116,0.9), rgba(65,72,116,0.1))' }}>
                                        <img class='mt-3' src={el.img} style={{ width: '60px' }} alt="" />
                                        <p class='fs-5 fw-bold my-3'>{el.title}</p>
                                        <p class='my-3'>{el.text}</p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <br />

            </div>

            <div class=' row flex-column-reverse flex-md-row '>
                <div class='col-md-6'>
                    <div class='col-sm-10 col-11 m-auto text-start my-4'>
                        <p class='fs-1 fw-bold' data-aos="fade-right" style={{ color: '#EA5136' }}>{projectDetailData?.section7?.title}</p>
                        <p class='my-2 fs-5' data-aos="fade-right">{projectDetailData?.section7?.text}</p>
                        {/* <div class='mt-3' data-aos="fade-right">
                            <button class="custom-btn btnBlue  "><span>{projectDetailData?.section7?.button1}</span></button>
                            <button class="custom-btn btnOrange"><span>{projectDetailData?.section7?.button2}</span></button>
                        </div> */}
                    </div>
                </div>
                <div class='col-md-6' data-aos="zoom-in">
                    <img src={projectDetailData?.section7?.img} style={{ width: '100%', minHeight: '300px' }} alt="" />
                </div>
            </div>


        </div>
    )
}

export default SakarMall